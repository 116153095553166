import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import { ResponsiveWidthContainer } from "../components/Section";
import { colors as c } from "../style";

const SectionTitle = styled.h2`
  text-align: left;
  font-size: 3rem;
  color: ${c.heading_alt};
  margin: 2rem auto;
  font-weight: 400;
  line-height: 3rem;
  margin: 0;
  padding-top: 5rem;
`;

const PageContentStyled = styled(HTMLContent)`
  color: ${c.heading_alt};
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
`;

const StyledResponsiveWidthContainer = styled(ResponsiveWidthContainer)`
  > section {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    min-height: 670px;
    justify-content: space-between;
  }
`;

export const BGImageTemplate = ({ title, bgImage, content }) => {
  return (
    <StyledResponsiveWidthContainer bgImage={bgImage || null} minHeight="770px">
      <SectionTitle>{title}</SectionTitle>
      <PageContentStyled content={content} />
    </StyledResponsiveWidthContainer>
  );
};

BGImageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  bgImage: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const BGImage = ({ data, location }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout location={location}>
      <BGImageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        bgImage={post.frontmatter.backgroundImage}
        content={post.html}
      />
    </Layout>
  );
};

BGImage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object
};

export default BGImage;

export const BGImageQuery = graphql`
  query BGImage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        backgroundImage
      }
    }
  }
`;
